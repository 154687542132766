import React, {useCallback, useState} from 'react';
import {GoogleMap, OverlayView, Polygon, useLoadScript} from "@react-google-maps/api";

const Schedule = (props) => {
    const [center, setCenter] = useState({ lat: -15.4510304, lng: 28.4576441 });
    const polygonCoordinates = props.geometry;

    const onLoad = useCallback(map => {
        const zoomLevel = 10;
        const centerLatLng = new window.google.maps.LatLng(center);
        map.setCenter(centerLatLng);
        map.setZoom(zoomLevel);

        // Create and add the polygon to the map
        if (Array.isArray(polygonCoordinates) && polygonCoordinates.length > 0) {
            const newPolygon = new window.google.maps.Polygon({
                paths: polygonCoordinates,
                strokeColor: '#FD2311',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FD2311',
                fillOpacity: 0.1
            });

            newPolygon.setMap(map);

            // Calculate bounds
            const bounds = new window.google.maps.LatLngBounds();
            polygonCoordinates.forEach(coord => bounds.extend(coord));

            // Fit the map to the polygon's bounds
            map.fitBounds(bounds);
        }
    }, [center, polygonCoordinates]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBcwPZxow61r9xp4GpD5CJDsJzz1WEXW8Y",
        libraries: ["drawing"]
    });

    const calculateCenter = (coordinates) => {
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(coord => bounds.extend(coord));
        return bounds.getCenter().toJSON();
    };

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        center={center}
                        onLoad={onLoad}
                        zoom={10}
                    />
                </div>
            )}
        </>
    );
};

export default Schedule;
