import React, {useEffect} from 'react';
import $ from "jquery";
import {GET_COLLECTION_DATA} from "../../api";
import cogoToast from "cogo-toast";
import {capitalize} from "../../utils/capitalize";
import moment from "moment";

let type = null;

const DTCustomer = ({coordinates, date, onTotalChange}) => {

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#td-collection').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Collection Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "type",
                        pageSize: data.start,
                        filter: {
                            type: "subscription",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: coordinates }},
                            "due_date.str": {$gt: moment(date).format("YYYY/MM/DD 23:59:59")}
                        },
                        format: 'json',
                        populate: [
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country'
                                }
                            },
                            {
                                path: 'user',
                                model: 'user',
                            },
                            {
                                path: 'product',
                                model: 'product',
                            },
                            {
                                path: 'address.property',
                                model: 'property',
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        onTotalChange(res.total);
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<a href="#/customer/${data.user._id}" target="_blank" class="text-dark">${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}</a>`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.user.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        let startOfDay = moment(date).startOf('day');
                        let endOfDay = moment(date).endOf('day');

                        let collect = data.collections.find((item) => {
                            let itemDate = moment(item.date.iso);
                            return itemDate.isBetween(startOfDay, endOfDay, null, '[]');
                        });

                        return {
                            collected: `<span class="badge badge-success tx-white">Collected</span>`,
                            uncollected: `<span class="badge badge-danger tx-white">Uncollected</span>`
                        }[collect ? collect.status : 'uncollected'];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return capitalize(data.address.meta.suburb);
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return moment(date).format("Do MMM, YYYY");
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
        });

        $('#td-collection tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            if(d == null) return null;

            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Total Amount:</td>' +
                '<td>' +
                d.raw.collector.country.currency.symbol + d.raw.meta.amount*d.raw.total_month*d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Number of Bin:</td>' +
                '<td>' +
                d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Date Issued:</td>' +
                '<td>'+moment(d.raw.date.iso).format("Do MMM, YYYY")+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        return () => {
            table.destroy();
            type = null;
        }

    }, [coordinates]);

    return (
        <table id="td-collection" className="table" style={{ width: "100%" }}>
            <thead>
            <tr>
                <th></th>
                <th className="wd-20p">Name</th>
                <th className="wd-20p">Mobile Number</th>
                <th className="wd-20p">Status</th>
                <th className="wd-20p">Area</th>
                <th className="wd-15p">Date</th>
            </tr>
            </thead>
        </table>
    );
};

export default DTCustomer;
