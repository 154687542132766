const API_URL = `${process.env.REACT_APP_API_URL}/v1/collector`;

exports.SIGNIN_URL = `${API_URL}/partner-sign-in`;
exports.UAC_URL = `${API_URL}/uac`;
exports.GET_COLLECTION_DATA = `${API_URL}/collection-data`;
exports.GET_ZONE_USER = `${API_URL}/zone-user`;
exports.GET_ZONE_PRODUCT_URL = `${API_URL}/zone-product`;
exports.USER_URL = `${API_URL}/user`;
exports.COUNTRIES_URL = `${API_URL}/get-countries`;
exports.COLLECTORS_URL = `${API_URL}/get-collectors`;
exports.COUNTRYS_URL =  `${API_URL}/get-countries`;
exports.ACCOUNT_URL = `${API_URL}/account`;
exports.GET_RATING_URL = `${API_URL}/rating`;
exports.SEARCH_COLLECTION_URL = `${API_URL}/search-collection`;
exports.GET_COLLECTION_AGGREGATE_DATA = `${API_URL}/collection-aggregate-data`;
exports.PRODUCT_URL = `${API_URL}/product`;
exports.GET_PRE_SIGNED_URL = `${API_URL}/generate-pre-signed-url`;
exports.GET_USER_REPORT_URL = `${API_URL}/user-report`;
exports.GET_WASTE_REPORT_URL = `${API_URL}/waste-report`;
exports.GET_REVENUE_REPORT_URL = `${API_URL}/revenue-report`;
exports.GET_COLLECTION_WASTE_REPORT_URL = `${API_URL}/collection-wastes-report`;
exports.GET_TRANSACTION_REPORT_URL = `${API_URL}/transaction-report`;
exports.GET_GENERAL_REPORT_URL = `${API_URL}/general-report`;
exports.GET_FINANCE_REPORT_URL = `${API_URL}/finance-report`;
exports.GET_ACCOUNT_BALANCE_URL = `${API_URL}/account-balance`;
exports.GET_SCHEDULES_URL = `${API_URL}/schedules`;
exports.GET_TERRITORIES = `${API_URL}/territories`;
exports.SUBURB_URL = `${API_URL}/suburb`;
exports.STATE_URL = `${API_URL}/state`;
exports.COUNTRY_URL = `${API_URL}/country`;
exports.COLLECTOR_URL = `${API_URL}/collector`;
exports.GET_ZONE_URL = `${API_URL}/zone`;
exports.AGENT_URL = `${API_URL}/agent`;
exports.TERRITORY_URL = `${API_URL}/territory`;
exports.NOTIFICATION_URL = `${API_URL}/notification`;
exports.CITY_URL = `${API_URL}/city`;
exports.FETCH_URL = `${API_URL}/image/`;
exports.WASTE_URL = `${API_URL}/waste`;
exports.GET_RATE_REPORT_URL = `${API_URL}/rate-report`;
exports.GET_SALE_REVENUE_REPORT_URL = `${API_URL}/sale-revenue-report`;
exports.GET_RECUR_REVENUE_REPORT_URL = `${API_URL}/recur-revenue-report`;
exports.GET_CUSTOMER_GROWTH_REPORT_URL = `${API_URL}/customer-growth-report`;
exports.USER_WASTE_URL = (id) => `${API_URL}/user-waste/${id}`;
exports.GET_COLLECTOR_URL = (id) => `${API_URL}/collector/${id}`;
exports.GET_USER_URL = (id) => `${API_URL}/user/${id}`;
exports.WASTE_DATA_URL = (id) => `${API_URL}/waste/${id}`;
