import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, Polygon, useLoadScript } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";
import { getZones } from "../../api/app";
import cogoToast from "cogo-toast";

const PinMap = ({ onPinLocation }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [loadingZones, setLoadingZones] = useState(true);
    const [coordinates, setCoordinates] = useState(null);
    const [polygonPath, setPolygonPath] = useState(null);

    useEffect(() => {
        getZones().then((response) => {
            if (!response.error) {
                const fetchedCoordinates = response.payload[0].geometry.coordinates.flatMap(array =>
                    array.map(([lat, lng]) => ({ lat, lng }))
                );
                setCoordinates(fetchedCoordinates);
                setPolygonPath(fetchedCoordinates.map(coord => new window.google.maps.LatLng(coord.lat, coord.lng)));
            }
            setLoadingZones(false);
        });
    }, []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"],
    });

    const onLoad = useCallback((map) => {
        if (coordinates) {
            const zoomLevel = 10;
            let bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach(coord => bounds.extend(coord));
            map.fitBounds(bounds);
            map.setZoom(zoomLevel);
        }
    }, [coordinates]);

    // Faster animation function
    const animateMarker = (startPos, endPos) => {
        const frames = 30; // Reduced frames for faster animation
        let frame = 0;

        const latStep = (endPos.lat - startPos.lat) / frames;
        const lngStep = (endPos.lng - startPos.lng) / frames;

        const interval = setInterval(() => {
            frame++;
            const newLat = startPos.lat + latStep * frame;
            const newLng = startPos.lng + lngStep * frame;

            setSelectedPosition({ lat: newLat, lng: newLng });

            if (frame >= frames) {
                clearInterval(interval); // Stop animation
                setSelectedPosition(endPos); // Ensure final position is set
            }
        }, 10); // Reduced interval for a quicker animation
    };

    const handleMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const clickedLocation = new window.google.maps.LatLng(lat, lng);

        if (polygonPath && window.google.maps.geometry.poly.containsLocation(clickedLocation, new window.google.maps.Polygon({ paths: polygonPath }))) {
            if (selectedPosition) {
                // Animate from current position to new position
                animateMarker(selectedPosition, { lat, lng });
            } else {
                // Directly set the marker if it's the first time
                setSelectedPosition({ lat, lng });
            }

            if (onPinLocation) {
                onPinLocation({ lat, lng });
            }
        } else {
            cogoToast.error("You can only pin locations inside the designated area.", { hideAfter: 5, position: "top-right" });
        }
    }, [onPinLocation, polygonPath, selectedPosition]);

    const isFullyLoaded = isLoaded && !loadingZones;

    const handlePolygonClick = useCallback((event) => {
        handleMapClick(event);
    }, [handleMapClick]);

    return (
        <>
            {!isFullyLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        onLoad={onLoad}
                        center={selectedPosition || { lat: 0, lng: 0 }}
                        zoom={selectedPosition ? 15 : 2}
                        options={{ styles: mapStyle }}
                        onClick={handleMapClick}
                    >
                        {selectedPosition && (
                            <Marker
                                position={selectedPosition}
                            />
                        )}
                        {coordinates && (
                            <Polygon
                                paths={coordinates}
                                options={{
                                    fillOpacity: 0.02,
                                    fillColor: "#FD2311",
                                    strokeColor: "#FD2311",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    clickable: true,
                                }}
                                onClick={handlePolygonClick}
                            />
                        )}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default PinMap;
