import React, {useEffect, useState} from 'react';
import $ from "jquery";
import {GET_COLLECTION_DATA} from "../../api";
import cogoToast from "cogo-toast";
import {capitalize} from "../../utils/capitalize";
import moment from "moment";

const currentDateRange = null;
let type = null;

const DTWaste = ({coordinates, onTotalChange, onTypeChange}) => {

    const token = localStorage.getItem('jwtToken');

    // const [currentDateRange, setCurrentDateRange] = useState({
    //     start: moment().format("YYYY/MM/DD 00:00:00"),
    //     end: moment().format("YYYY/MM/DD 23:59:59")
    // });

    const [activeLink, setActiveLink] = useState('All');

    useEffect(() => {
        let table = $('#td-waste').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Waste Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "type",
                        pageSize: data.start,
                        filter: currentDateRange !== null ? {
                            //Important to update it.
                            type: "subscription",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: coordinates }},
                            "date.iso":
                                {
                                    $gte: currentDateRange.start,
                                    $lte: currentDateRange.end
                                }
                        } : {
                            type: "subscription",
                            status: type === null ? {} : type,
                            "address.location": { $geoWithin: { $geometry: coordinates } },
                            "due_date.str": {$gt: moment().format("YYYY/MM/DD 23:59:59")}
                        },
                        format: 'json',
                        populate: [
                            {
                                path: 'collector',
                                model: 'collector',
                                populate: {
                                    path: 'country',
                                    model: 'country'
                                }

                            },
                            {
                                path: 'user',
                                model: 'user',
                            },
                            {
                                path: 'product',
                                model: 'product',
                            },
                            {
                                path: 'address.property',
                                model: 'property',
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        onTotalChange(res.total);
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<a href="#/customer/${data.user._id}" target="_blank" class="text-dark">${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}</a>`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.user.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            expired : `<span class="badge badge-danger tx-white">Expired</span>`,
                            paid : `<span class="badge badge-success tx-white">Paid</span>`,
                            pending : `<span class="badge badge-primary tx-white">Pending</span>`,
                            awaiting : `<span class="badge badge-secondary tx-white">Awaiting</span>`,
                            credit : `<span class="badge badge-warning tx-white">Credit</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return capitalize(data.address.meta.suburb);
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return data.status !== "pending" ? data.due_date.str > moment(new Date()).format("YYYY/MM/DD HH:mm") ? `<span class="tx-purple">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="tx-danger">${moment(data.due_date.iso).format("Do MMM, YYYY")}</span>` : `<span class="badge badge-warning tx-white">Pending</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
        });

        $('#td-waste tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            if(d == null) return null;

            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Total Amount:</td>' +
                '<td>' +
                d.raw.collector.country.currency.symbol + d.raw.meta.amount*d.raw.total_month*d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Number of Bin:</td>' +
                '<td>' +
                d.raw.bin +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                capitalize(d.raw.address.property.type) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Date Issued:</td>' +
                '<td>'+moment(d.raw.date.iso).format("Do MMM, YYYY")+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        return () => {
            table.destroy();
            // currentDateRange = null;
            // total = 0;
        }

    }, [coordinates]);

    const handleNavClick = (linkName) => {
        setActiveLink(linkName);
        type = linkName === "All" ? null : linkName.toLowerCase();
        onTypeChange(type);
        $('#td-waste').DataTable().ajax.reload(null, false);
    }

    return (
        <div>
            <div className="btn-group mg-t-20 mg-sm-t-0 mb-20 mg-b-20">
                <button className={`btn btn-xs btn-white btn-uppercase ${activeLink === 'All' ? 'active' : ''}`} onClick={() => handleNavClick('All')}>All</button>
                <button className={`btn btn-xs btn-white btn-uppercase ${activeLink === 'Paid' ? 'active' : ''}`} onClick={() => handleNavClick('Paid')}>Paid</button>
                <button className={`btn btn-xs btn-white btn-uppercase ${activeLink === 'Credit' ? 'active' : ''}`} onClick={() => handleNavClick('Credit')}>Credit</button>
                <button className={`btn btn-xs btn-white btn-uppercase ${activeLink === 'Expired' ? 'active' : ''}`} onClick={() => handleNavClick('Expired')}>Expired</button>
                <button className={`btn btn-xs btn-white btn-uppercase ${activeLink === 'Cancelled' ? 'active' : ''}`} onClick={() => handleNavClick('Cancelled')}>Cancelled</button>
            </div>
            <table id="td-waste" className="table" style={{width: "100%"}}>
                <thead>
                <tr>
                    <th></th>
                    <th className="wd-20p">Name</th>
                    <th className="wd-20p">Mobile Number</th>
                    <th className="wd-20p">Status</th>
                    <th className="wd-20p">Area</th>
                    <th className="wd-15p">Expiry Date</th>
                </tr>
                </thead>
            </table>
        </div>
    );
};

export default DTWaste;
