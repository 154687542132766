import React, {useEffect, useState} from 'react';
import $ from "jquery";
import {capitalize} from "../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getSchedules} from "../../api/app";
import "components-jqueryui";
import moment from "moment/moment";
import "fullcalendar";
import ScheduleMap from "../../components/map/Schedule";
import {GET_COLLECTION_DATA, GET_ZONE_USER} from "../../api";
import exportToExcel from "../../utils/exporter";
import DTWaste from "../../components/datatable/DTWaste";
import DTCollection from "../../components/datatable/DTCollection";

var curYear = moment().format('YYYY');
var curMonth = moment().format('MM');

let events = [];
let total = 0;
let type = null;
const currentDateRange = null;

const colorSets = [
    { backgroundColor: '#d9e8ff', borderColor: '#0168fa' },
    { backgroundColor: '#c3edd5', borderColor: '#10b759' },
    { backgroundColor: '#fcbfdc', borderColor: '#f10075' },
    { backgroundColor: '#bff2f2', borderColor: '#00cccc' },
    { backgroundColor: '#ffdec4', borderColor: '#fd7e14' },
    { backgroundColor: '#ffe5b9', borderColor: '#ffb703' },
    { backgroundColor: '#d6f5e3', borderColor: '#34d399' },
    { backgroundColor: '#fde2e2', borderColor: '#f87171' },
    { backgroundColor: '#e0e7ff', borderColor: '#6366f1' }
];

const Schedule = (props) => {

    document.title = "Schedule";

    const token = localStorage.getItem('jwtToken');

    const [openMap, setOpenMap] = useState(false);
    const [coordinates, setCoordinates] = useState([]);
    const [date, setDate] = useState(moment().toISOString());
    const [exportLoading, setExportLoading] = useState(false);

    useEffect(() => {
        getSchedules().then((response) => {
           updateData(response.payload)
        })
    }, []);

    useEffect(() => {
        $('#calendar').fullCalendar({
            height: 'parent',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listWeek'
            },
            navLinks: true,
            selectable: true,
            selectLongPressDelay: 100,
            editable: true,
            nowIndicator: true,
            defaultView: 'listMonth',
            views: {
                agenda: {
                    columnHeaderHtml: function(mom) {
                        return '<span>' + mom.format('ddd') + '</span>' +
                            '<span>' + mom.format('DD') + '</span>';
                    }
                },
                day: { columnHeader: false },
                listMonth: {
                    listDayFormat: 'ddd DD',
                    listDayAltFormat: false
                },
                listWeek: {
                    listDayFormat: 'ddd DD',
                    listDayAltFormat: false
                },
                agendaThreeDay: {
                    type: 'agenda',
                    duration: { days: 3 },
                    titleFormat: 'MMMM YYYY'
                }
            },
            // eventSources: [calender],
            eventSources: [events],
            eventAfterAllRender: function(view) {
                if(view.name === 'listMonth' || view.name === 'listWeek') {
                    var dates = view.el.find('.fc-list-heading-main');
                    dates.each(function(){
                        var text = $(this).text().split(' ');
                        var now = moment().format('DD');

                        $(this).html(text[0]+'<span>'+text[1]+'</span>');
                        if(now === text[1]) { $(this).addClass('now'); }
                    });
                }
            },
            eventRender: function(event, element) {

                if(event.description) {
                    element.find('.fc-list-item-title').append('<span class="fc-desc">' + event.description + '</span>');
                    element.find('.fc-content').append('<span class="fc-desc">' + event.description + '</span>');
                }

                var eBorderColor = (event.source.borderColor)? event.source.borderColor : event.borderColor;
                element.find('.fc-list-item-time').css({
                    color: eBorderColor,
                    borderColor: eBorderColor
                });

                element.find('.fc-list-item-title').css({
                    borderColor: eBorderColor
                });

                element.css('borderLeftColor', eBorderColor);
            },
        });

        var calendar = $('#calendar').fullCalendar('getCalendar');

        // change view to week when in tablet
        if(window.matchMedia('(min-width: 576px)').matches) {
            calendar.changeView('agendaWeek');
        }

        // change view to month when in desktop
        if(window.matchMedia('(min-width: 992px)').matches) {
            calendar.changeView('month');
        }

        // change view based in viewport width when resize is detected
        calendar.option('windowResize', function(view) {
            if(view.name === 'listWeek') {
                if(window.matchMedia('(min-width: 992px)').matches) {
                    calendar.changeView('month');
                } else {
                    calendar.changeView('listWeek');
                }
            }
        });

        // Display calendar event modal
        calendar.on('eventClick', function(calEvent, jsEvent, view){
            var modal = $('#modalCalendarEvent');

            modal.modal('show');
            modal.find('.event-title').text(calEvent.title);

            setDate(moment(calEvent.start).toISOString());
            setCoordinates(calEvent.coordinates);

            if(calEvent.description) {
                modal.find('.event-desc').text(calEvent.description);
                modal.find('.event-desc').prev().removeClass('d-none');
            } else {
                modal.find('.event-desc').text('');
                modal.find('.event-desc').prev().addClass('d-none');
            }

            modal.find('.event-start-date').text(moment(calEvent.start).format('LLL'));
            modal.find('.event-end-date').text(moment(calEvent.end).format('LLL'));

            //styling
            modal.find('.modal-header').css('backgroundColor', (calEvent.source.borderColor)? calEvent.source.borderColor : calEvent.borderColor);

            setOpenMap(true);
        });

        // display current date
        var dateNow = calendar.getDate();
        calendar.option('select', function(startDate, endDate){
            $('#modalCreateEvent').modal('show');
            $('#eventStartDate').val(startDate.format('LL'));
            $('#eventEndDate').val(endDate.format('LL'));

            $('#eventStartTime').val(startDate.format('LT')).trigger('change');
            $('#eventEndTime').val(endDate.format('LT')).trigger('change');
        });

        $('.select2-modal').select2({
            minimumResultsForSearch: Infinity,
            dropdownCssClass: 'select2-dropdown-modal',
        });

        // $('.calendar-add').on('click', function(e){
        //     e.preventDefault()
        //
        //     $('#modalCreateEvent').modal('show');
        // });
    }, []);

    const getRandomColorSet = () => {
        const randomIndex = Math.floor(Math.random() * colorSets.length);
        return colorSets[randomIndex];
    };

    const updateData = (data) => {
        let array = [];

        const getDatesForDayInMonth = (dayName, month, year) => {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let result = [];
            let dayIndex = daysOfWeek.indexOf(dayName);

            for (let day = 1; day <= 31; day++) {
                let currentDate = new Date(year, month, day);
                if (currentDate.getMonth() !== month) break;
                if (currentDate.getDay() === dayIndex) {
                    result.push(currentDate);
                }
            }

            return result;
        };

        let curYear = new Date().getFullYear();
        let curMonth = new Date().getMonth();

        data.map((item) => {
            if (item.days && item.days.length > 0) {
                let dayName = item.days[0];
                let datesForDay = getDatesForDayInMonth(dayName, curMonth, curYear);

                datesForDay.forEach((date) => {
                    let startDate = moment(date).set({ hour: 8, minute: 0 });
                    let endDate = moment(date).set({ hour: 18, minute: 0 });

                    const { backgroundColor, borderColor } = getRandomColorSet();

                    array.push({
                        id: item._id,
                        title: item.name || "No Title",
                        coordinates: item.geometry,
                        start: startDate.format(),
                        end: endDate.format(),
                        description: item.description || "No Description",
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                    });
                });
            }
        });

        $('#calendar').fullCalendar('removeEvents');
        $('#calendar').fullCalendar('addEventSource', array);
        $('#calendar').fullCalendar('rerenderEvents');
    }

    const convertCoordinates = (document) => {
        const coordinates = document.coordinates[0];
        return coordinates.map(coord => ({
            lat: coord[0],
            lng: coord[1]
        }));
    };

    const exportData = () => {
        setExportLoading(true);
        $.ajax(GET_ZONE_USER, {
            type: 'POST',
            headers: {
                "Authorization": token
            },
            data: {
                pageSize: 0,
                limit: total,
                geometry: coordinates,
                format: 'json',
                populate: "country"
            },
            success: function (res) {
                setExportLoading(false);
                const filteredData = res.data.map(item => ({
                    first_name: capitalize(item.first_name),
                    last_name: capitalize(item.last_name),
                    mobile_number: item.mobile_number.toString(),
                    subscribed: item.subscribe ? "Yes" : "No",
                    date: moment(item.date.iso).format("Do MMM, YYYY")
                }));

                const headers = [
                    "First Name",
                    "Last Name",
                    "Mobile Number",
                    "Subscribed",
                    "Date Issued"
                ];

                exportToExcel(headers, filteredData, "Customer");
            },
            error: function(err) {
                cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
            }
        });
    }

    const exportWasteData = () => {
        setExportLoading(true);
        $.ajax(GET_COLLECTION_DATA, {
            type: 'POST',
            headers: {
                "Authorization": token
            },
            data: {
                collection: "waste",
                fieldname: "type",
                limit: total,
                pageSize: 0,
                filter: currentDateRange !== null ? {
                    type: "subscription",
                    status: type === null ? {} : type,
                    "address.location": { $geoWithin: { $geometry: coordinates }},
                    "date.iso":
                        {
                            $gte: currentDateRange.start,
                            $lte: currentDateRange.end
                        }
                } : {
                    type: "subscription",
                    status: type === null ? {} : type,
                    "address.location": { $geoWithin: { $geometry: coordinates } }
                },
                format: 'json',
                populate: [
                    {
                        path: 'user',
                        model: 'user',
                        select: 'first_name last_name mobile_number gender'
                    },
                    {
                        path: 'product',
                        model: 'product',
                    },
                    {
                        path: 'transaction',
                        model: 'transaction'
                    },
                    {
                        path: 'address.property',
                        model: 'property',
                    }
                ]
            },
            success: function (res) {
                setExportLoading(false);
                const filteredData = res.data.map(item => ({
                    first_name: capitalize(item.user.first_name),
                    last_name: capitalize(item.user.last_name),
                    gender: capitalize(item.user.gender),
                    mobile_number: capitalize(item.user.mobile_number.toString()),
                    suburb: capitalize(item.address.meta.suburb),
                    unit_number: capitalize(item.address.unit_number),
                    street_address: capitalize(item.address.meta.street_address),
                    bin: item.bin,
                    total_month: item.total_month,
                    total_amount: item.meta.amount * item.total_month * item.bin,
                    property_type: capitalize(item.address.property.type),
                    expiry: item.due_date.str,
                    gateway: capitalize(item.transaction.gateway),
                    status: capitalize(item.status)
                }));

                const headers = [
                    "First Name",
                    "Last Name",
                    "Gender",
                    "Mobile Number",
                    "Area",
                    "Unit Number",
                    "Street Address",
                    "Total Bin",
                    "Total Month",
                    "Total Amount",
                    "Property Type",
                    "Expiry",
                    "Payment Method",
                    "Status"
                ];

                exportToExcel(headers, filteredData, "Subscription");
            },
            error: function(err) {
                cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
            }
        });
    }

    return (
        <div className="content-body pd-0">
            <div id="calendar" className="calendar-content-body"></div>

            {/*<div className="modal calendar-modal-create fade effect-scale" id="modalCreateEvent" role="dialog"*/}
            {/*     aria-hidden="true">*/}
            {/*    <div className="modal-dialog modal-dialog-centered" role="document">*/}
            {/*        <div className="modal-content">*/}
            {/*            <div className="modal-body pd-20 pd-sm-30">*/}
            {/*                <button type="button" className="close pos-absolute t-20 r-20" data-dismiss="modal"*/}
            {/*                        aria-label="Close">*/}
            {/*                    <span aria-hidden="true"><i data-feather="x"></i></span>*/}
            {/*                </button>*/}

            {/*                <h5 className="tx-18 tx-sm-20 mg-b-20 mg-sm-b-30">Create New Event</h5>*/}

            {/*                <form id="formCalendar" method="post" action="app-calendar.html">*/}
            {/*                    <div className="form-group">*/}
            {/*                        <input type="text" className="form-control" placeholder="Add title"/>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group d-flex align-items-center">*/}
            {/*                        <div className="custom-control custom-radio">*/}
            {/*                            <input type="radio" id="customRadio1" name="customRadio"*/}
            {/*                                   className="custom-control-input" checked/>*/}
            {/*                            <label className="custom-control-label" htmlFor="customRadio1">Event</label>*/}
            {/*                        </div>*/}
            {/*                        <div className="custom-control custom-radio mg-l-20">*/}
            {/*                            <input type="radio" id="customRadio2" name="customRadio"*/}
            {/*                                   className="custom-control-input" checked/>*/}
            {/*                            <label className="custom-control-label" htmlFor="customRadio2">Reminder</label>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group mg-t-30">*/}
            {/*                        <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Start*/}
            {/*                            Date</label>*/}
            {/*                        <div className="row row-xs">*/}
            {/*                            <div className="col-7">*/}
            {/*                                <input id="eventStartDate" type="text" value="" className="form-control"*/}
            {/*                                       placeholder="Select date"/>*/}
            {/*                            </div>*/}

            {/*                            <div className="col-5">*/}
            {/*                                <select className="custom-select">*/}
            {/*                                    <option selected>Select Time</option>*/}
            {/*                                </select>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group">*/}
            {/*                        <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">End*/}
            {/*                            Date</label>*/}
            {/*                        <div className="row row-xs">*/}
            {/*                            <div className="col-7">*/}
            {/*                                <input id="eventEndDate" type="text" value="" className="form-control"*/}
            {/*                                       placeholder="Select date"/>*/}
            {/*                            </div>*/}

            {/*                            <div className="col-5">*/}
            {/*                                <select className="custom-select">*/}
            {/*                                    <option selected>Select Time</option>*/}
            {/*                                </select>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}

            {/*                    <div className="form-group">*/}
            {/*                        <textarea className="form-control" rows="2"*/}
            {/*                                  placeholder="Write some description (optional)"></textarea>*/}
            {/*                    </div>*/}

            {/*                </form>*/}
            {/*            </div>*/}

            {/*            <div className="modal-footer">*/}
            {/*                <button type="submit" className="btn btn-primary mg-r-5">Add Event</button>*/}
            {/*                <a href="" className="btn btn-secondary" data-dismiss="modal">Discard</a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="modal calendar-modal-event fade effect-scale" id="modalCalendarEvent" role="dialog"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="event-title"></h6>
                            {/*<nav className="nav nav-modal-event">*/}
                            {/*    <a href="#" className="nav-link"><i data-feather="external-link"></i></a>*/}
                            {/*    <a href="#" className="nav-link"><i data-feather="trash-2"></i></a>*/}
                            {/*    <a href="#" className="nav-link" data-dismiss="modal"><i data-feather="x"></i></a>*/}
                            {/*</nav>*/}
                        </div>

                        <div className="modal-body pd-10-f">
                            <ul className="nav nav-line nav-fill" id="myTab5" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="info-tab5" data-toggle="tab" href="#info" role="tab" aria-controls="home" aria-selected="true">Information</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="waste-tab5" data-toggle="tab" href="#waste" role="tab" aria-controls="waste" aria-selected="false">Wastes</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="collection-tab5" data-toggle="tab" href="#collection" role="tab" aria-controls="collection" aria-selected="false">Collection</a>
                                </li>
                            </ul>

                            <div className="tab-content mg-t-20 pd-sm-b-20 pd-sm-x-20" id="myTabContent5" >
                                <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab5">
                                    <div className="row row-sm">
                                        <div className="col-sm-6">
                                            <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Start Date</label>
                                            <p className="event-start-date"></p>
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">End Date</label>
                                            <p className="event-end-date"></p>
                                        </div>
                                    </div>

                                    <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Description</label>
                                    <p className="event-desc tx-gray-900 mg-b-20"></p>

                                    {openMap ? <ScheduleMap geometry={convertCoordinates(coordinates)}/> : null}
                                    <button className="btn btn-secondary btn-uppercase float-right pd-x-20 mt-4 mb-3" data-dismiss="modal" onClick={() => setOpenMap(false)}> Close</button>
                                </div>
                                <div className="tab-pane fade" id="waste" role="tabpanel" aria-labelledby="waste-tab5">
                                    <DTWaste coordinates={coordinates} onTotalChange={(data) => total = data} onTypeChange={(data) => type = data}/>
                                    <div className="d-flex justify-content-between mt-3">
                                        {exportLoading ? <button disabled className="btn btn-primary btn-uppercase"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Exporting</button> : <button className="btn btn btn-primary btn-uppercase" onClick={exportWasteData.bind()}><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>}
                                        <button className="btn btn-secondary btn-uppercase" data-dismiss="modal"> Close</button>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="collection" role="tabpanel" aria-labelledby="collection-tab5">
                                    <DTCollection coordinates={coordinates} date={date} onTotalChange={(data) => total = data}/>
                                    <div className="d-flex justify-content-between mt-3">
                                        {exportLoading ? <button disabled className="btn btn-primary btn-uppercase"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Exporting</button> : <button className="btn btn btn-primary btn-uppercase"><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>}
                                        <button className="btn btn-secondary btn-uppercase" data-dismiss="modal"> Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Schedule;
